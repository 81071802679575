import React from "react"
import { AspectRatioBox, Box } from "@chakra-ui/core"

const Video = ({ videoSrcURL, videoTitle,videoWidth, ...props }) => (
  <AspectRatioBox w={videoWidth} ratio={1.78}>
    <Box
      as="iframe"
      src={videoSrcURL}
      title={videoTitle}
      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
      frameBorder="0"
      webkitallowfullscreen="true"
      mozallowfullscreen="true"
      allowFullScreen
    />
  </AspectRatioBox>
)
export default Video
